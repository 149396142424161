/*Atomic Bulldog version 1.0.0*/

//Global settings
$use-css-var: true;

//functions
@import "functions/em";
@import "functions/map-deep-get";

//mixins
@import "mixins/media-queries";

//variables
@import "variables/root-classes";
@import "variables/breakpoints";
@import "variables/container-sizes";
@import "variables/grid";
@import "variables/spacers";

//layout
@import "layouts/container";
@import "layouts/grid";