.container {
  padding-right: 1rem;
  padding-left: 1rem;

  &:not(.is-fluid) {
    margin: 0 auto;

    @each $bp, $container-size in $container-sizes {
      @include min(#{bp(#{$bp})}) {
        width: container(#{$bp});
      }
    }
  }
}

// ------------------------------
// KSS Documentation
// ------------------------------

// Container
//
// Atomic Bulldog comes with two containers classes that you can choose from. One fluid container, that will take all the available space.
// One fixed width container (sizes are set in variables/_container-sizes.scss).
//
// Styleguide Layout.Container

// Container Fluid
//
// `.container.is-fluid`
//
// markup:
// <div class="container-fluid is-fluid">
//   <div class="box">
//    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem quisquam ea consequuntur nostrum sint. Explicabo
//    neque architecto sint voluptatibus ullam et ea recusandae nihil unde quos amet hic, ut iste?
//   </div>
// </div>
//
// Styleguide Layout.Container.fluid

// Container fixed
//
// `.container`
//
// Sizes are set in variables/_container-sizes.scss
//
// markup:
// <div class="container">
//   <div class="box">
//    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem quisquam ea consequuntur nostrum sint. Explicabo
//    neque architecto sint voluptatibus ullam et ea recusandae nihil unde quos amet hic, ut iste?
//   </div>
// </div>
//
// Styleguide Layout.Container.fixed