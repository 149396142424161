/*Atomic Bulldog version 1.0.0*/
/*Min and Max mixins*/

:root,
.theme-default,
.is-theme-default {
  --bp-xs: 320px;
  --bp-sm: 600px;
  --bp-md: 900px;
  --bp-lg: 1200px;
  --bp-xl: 1800px;
}


:root,
.theme-default,
.is-theme-default {
  --container-size-sm: 570px;
  --container-size-md: 860px;
  --container-size-lg: 1150px;
  --container-size-xl: 1400px;
}


:root,
.theme-default,
.is-theme-default {
  --spacer-0: 0;
  --spacer-1: 0.25rem;
  --spacer-2: 0.5rem;
  --spacer-3: 1rem;
  --spacer-4: 1.25rem;
  --spacer-5: 1.5rem;
  --spacer-6: 3rem;
  --spacer-7: 6rem;
  --spacer-8: 9rem;
  --spacer-9: 12rem;
}

.container {
  padding-right: 1rem;
  padding-left: 1rem;
}

.container:not(.is-fluid) {
  margin: 0 auto;
}

@media only screen and (min-width: 37.5em) {
  .container:not(.is-fluid) {
    width: var(--container-size-sm);
  }
}

@media only screen and (min-width: 56.25em) {
  .container:not(.is-fluid) {
    width: var(--container-size-md);
  }
}

@media only screen and (min-width: 75em) {
  .container:not(.is-fluid) {
    width: var(--container-size-lg);
  }
}

@media only screen and (min-width: 112.5em) {
  .container:not(.is-fluid) {
    width: var(--container-size-xl);
  }
}

@supports (display: grid) {
  .grid {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-gap: var(--spacer-1);
  }
  .grid.is-dense {
    grid-auto-flow: dense;
  }
  .grid .has-col-all {
    grid-column: 1 / -1;
  }
  .grid .has-row-all {
    grid-row: 1 / -1;
  }
  .grid .has-col-1 {
    grid-column: span 1;
  }
  .grid.has-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid .has-col-2 {
    grid-column: span 2;
  }
  .grid.has-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid .has-col-3 {
    grid-column: span 3;
  }
  .grid.has-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid .has-col-4 {
    grid-column: span 4;
  }
  .grid.has-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid .has-col-5 {
    grid-column: span 5;
  }
  .grid.has-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid .has-col-6 {
    grid-column: span 6;
  }
  .grid.has-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid .has-col-7 {
    grid-column: span 7;
  }
  .grid.has-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid .has-col-8 {
    grid-column: span 8;
  }
  .grid.has-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .grid .has-col-9 {
    grid-column: span 9;
  }
  .grid.has-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .grid .has-col-10 {
    grid-column: span 10;
  }
  .grid.has-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .grid .has-col-11 {
    grid-column: span 11;
  }
  .grid.has-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .grid .has-col-12 {
    grid-column: span 12;
  }
  .grid.has-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .grid .has-row-1 {
    grid-row: span 1;
  }
  .grid .has-row-2 {
    grid-row: span 2;
  }
  .grid .has-row-3 {
    grid-row: span 3;
  }
  .grid .has-row-4 {
    grid-row: span 4;
  }
  .grid .has-row-5 {
    grid-row: span 5;
  }
  .grid .has-row-6 {
    grid-row: span 6;
  }
  .grid .has-row-7 {
    grid-row: span 7;
  }
  .grid .has-row-8 {
    grid-row: span 8;
  }
  .grid .has-row-9 {
    grid-row: span 9;
  }
  .grid .has-row-10 {
    grid-row: span 10;
  }
  .grid.has-grid-gap-0 {
    grid-gap: var(--spacer-0);
  }
  .grid.has-grid-gap-1 {
    grid-gap: var(--spacer-1);
  }
  .grid.has-grid-gap-2 {
    grid-gap: var(--spacer-2);
  }
  .grid.has-grid-gap-3 {
    grid-gap: var(--spacer-3);
  }
  .grid.has-grid-gap-4 {
    grid-gap: var(--spacer-4);
  }
  .grid.has-grid-gap-5 {
    grid-gap: var(--spacer-5);
  }
  .grid.has-grid-gap-6 {
    grid-gap: var(--spacer-6);
  }
  .grid.has-grid-gap-7 {
    grid-gap: var(--spacer-7);
  }
  .grid.has-grid-gap-8 {
    grid-gap: var(--spacer-8);
  }
  .grid.has-grid-gap-9 {
    grid-gap: var(--spacer-9);
  }
  @media only screen and (min-width: 20em) {
    .grid .has-col-xs-all {
      grid-column: 1 / -1;
    }
    .grid .has-row-xs-all {
      grid-row: 1 / -1;
    }
    .grid .has-col-xs-1 {
      grid-column: span 1;
    }
    .grid.has-cols-xs-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .grid .has-col-xs-2 {
      grid-column: span 2;
    }
    .grid.has-cols-xs-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .grid .has-col-xs-3 {
      grid-column: span 3;
    }
    .grid.has-cols-xs-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .grid .has-col-xs-4 {
      grid-column: span 4;
    }
    .grid.has-cols-xs-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .grid .has-col-xs-5 {
      grid-column: span 5;
    }
    .grid.has-cols-xs-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .grid .has-col-xs-6 {
      grid-column: span 6;
    }
    .grid.has-cols-xs-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .grid .has-col-xs-7 {
      grid-column: span 7;
    }
    .grid.has-cols-xs-7 {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .grid .has-col-xs-8 {
      grid-column: span 8;
    }
    .grid.has-cols-xs-8 {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .grid .has-col-xs-9 {
      grid-column: span 9;
    }
    .grid.has-cols-xs-9 {
      grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .grid .has-col-xs-10 {
      grid-column: span 10;
    }
    .grid.has-cols-xs-10 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .grid .has-col-xs-11 {
      grid-column: span 11;
    }
    .grid.has-cols-xs-11 {
      grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .grid .has-col-xs-12 {
      grid-column: span 12;
    }
    .grid.has-cols-xs-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .grid .has-row-xs-1 {
      grid-row: span 1;
    }
    .grid .has-row-xs-2 {
      grid-row: span 2;
    }
    .grid .has-row-xs-3 {
      grid-row: span 3;
    }
    .grid .has-row-xs-4 {
      grid-row: span 4;
    }
    .grid .has-row-xs-5 {
      grid-row: span 5;
    }
    .grid .has-row-xs-6 {
      grid-row: span 6;
    }
    .grid .has-row-xs-7 {
      grid-row: span 7;
    }
    .grid .has-row-xs-8 {
      grid-row: span 8;
    }
    .grid .has-row-xs-9 {
      grid-row: span 9;
    }
    .grid .has-row-xs-10 {
      grid-row: span 10;
    }
    .grid .has-row-xs-11 {
      grid-row: span 11;
    }
    .grid .has-row-xs-12 {
      grid-row: span 12;
    }
    .grid.has-grid-gap-xs-0 {
      grid-gap: var(--spacer-0);
    }
    .grid.has-grid-gap-xs-1 {
      grid-gap: var(--spacer-1);
    }
    .grid.has-grid-gap-xs-2 {
      grid-gap: var(--spacer-2);
    }
    .grid.has-grid-gap-xs-3 {
      grid-gap: var(--spacer-3);
    }
    .grid.has-grid-gap-xs-4 {
      grid-gap: var(--spacer-4);
    }
    .grid.has-grid-gap-xs-5 {
      grid-gap: var(--spacer-5);
    }
    .grid.has-grid-gap-xs-6 {
      grid-gap: var(--spacer-6);
    }
    .grid.has-grid-gap-xs-7 {
      grid-gap: var(--spacer-7);
    }
    .grid.has-grid-gap-xs-8 {
      grid-gap: var(--spacer-8);
    }
    .grid.has-grid-gap-xs-9 {
      grid-gap: var(--spacer-9);
    }
  }
  @media only screen and (min-width: 37.5em) {
    .grid .has-col-sm-all {
      grid-column: 1 / -1;
    }
    .grid .has-row-sm-all {
      grid-row: 1 / -1;
    }
    .grid .has-col-sm-1 {
      grid-column: span 1;
    }
    .grid.has-cols-sm-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .grid .has-col-sm-2 {
      grid-column: span 2;
    }
    .grid.has-cols-sm-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .grid .has-col-sm-3 {
      grid-column: span 3;
    }
    .grid.has-cols-sm-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .grid .has-col-sm-4 {
      grid-column: span 4;
    }
    .grid.has-cols-sm-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .grid .has-col-sm-5 {
      grid-column: span 5;
    }
    .grid.has-cols-sm-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .grid .has-col-sm-6 {
      grid-column: span 6;
    }
    .grid.has-cols-sm-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .grid .has-col-sm-7 {
      grid-column: span 7;
    }
    .grid.has-cols-sm-7 {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .grid .has-col-sm-8 {
      grid-column: span 8;
    }
    .grid.has-cols-sm-8 {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .grid .has-col-sm-9 {
      grid-column: span 9;
    }
    .grid.has-cols-sm-9 {
      grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .grid .has-col-sm-10 {
      grid-column: span 10;
    }
    .grid.has-cols-sm-10 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .grid .has-col-sm-11 {
      grid-column: span 11;
    }
    .grid.has-cols-sm-11 {
      grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .grid .has-col-sm-12 {
      grid-column: span 12;
    }
    .grid.has-cols-sm-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .grid .has-row-sm-1 {
      grid-row: span 1;
    }
    .grid .has-row-sm-2 {
      grid-row: span 2;
    }
    .grid .has-row-sm-3 {
      grid-row: span 3;
    }
    .grid .has-row-sm-4 {
      grid-row: span 4;
    }
    .grid .has-row-sm-5 {
      grid-row: span 5;
    }
    .grid .has-row-sm-6 {
      grid-row: span 6;
    }
    .grid .has-row-sm-7 {
      grid-row: span 7;
    }
    .grid .has-row-sm-8 {
      grid-row: span 8;
    }
    .grid .has-row-sm-9 {
      grid-row: span 9;
    }
    .grid .has-row-sm-10 {
      grid-row: span 10;
    }
    .grid .has-row-sm-11 {
      grid-row: span 11;
    }
    .grid .has-row-sm-12 {
      grid-row: span 12;
    }
    .grid.has-grid-gap-sm-0 {
      grid-gap: var(--spacer-0);
    }
    .grid.has-grid-gap-sm-1 {
      grid-gap: var(--spacer-1);
    }
    .grid.has-grid-gap-sm-2 {
      grid-gap: var(--spacer-2);
    }
    .grid.has-grid-gap-sm-3 {
      grid-gap: var(--spacer-3);
    }
    .grid.has-grid-gap-sm-4 {
      grid-gap: var(--spacer-4);
    }
    .grid.has-grid-gap-sm-5 {
      grid-gap: var(--spacer-5);
    }
    .grid.has-grid-gap-sm-6 {
      grid-gap: var(--spacer-6);
    }
    .grid.has-grid-gap-sm-7 {
      grid-gap: var(--spacer-7);
    }
    .grid.has-grid-gap-sm-8 {
      grid-gap: var(--spacer-8);
    }
    .grid.has-grid-gap-sm-9 {
      grid-gap: var(--spacer-9);
    }
  }
  @media only screen and (min-width: 56.25em) {
    .grid .has-col-md-all {
      grid-column: 1 / -1;
    }
    .grid .has-row-md-all {
      grid-row: 1 / -1;
    }
    .grid .has-col-md-1 {
      grid-column: span 1;
    }
    .grid.has-cols-md-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .grid .has-col-md-2 {
      grid-column: span 2;
    }
    .grid.has-cols-md-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .grid .has-col-md-3 {
      grid-column: span 3;
    }
    .grid.has-cols-md-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .grid .has-col-md-4 {
      grid-column: span 4;
    }
    .grid.has-cols-md-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .grid .has-col-md-5 {
      grid-column: span 5;
    }
    .grid.has-cols-md-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .grid .has-col-md-6 {
      grid-column: span 6;
    }
    .grid.has-cols-md-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .grid .has-col-md-7 {
      grid-column: span 7;
    }
    .grid.has-cols-md-7 {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .grid .has-col-md-8 {
      grid-column: span 8;
    }
    .grid.has-cols-md-8 {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .grid .has-col-md-9 {
      grid-column: span 9;
    }
    .grid.has-cols-md-9 {
      grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .grid .has-col-md-10 {
      grid-column: span 10;
    }
    .grid.has-cols-md-10 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .grid .has-col-md-11 {
      grid-column: span 11;
    }
    .grid.has-cols-md-11 {
      grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .grid .has-col-md-12 {
      grid-column: span 12;
    }
    .grid.has-cols-md-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .grid .has-row-md-1 {
      grid-row: span 1;
    }
    .grid .has-row-md-2 {
      grid-row: span 2;
    }
    .grid .has-row-md-3 {
      grid-row: span 3;
    }
    .grid .has-row-md-4 {
      grid-row: span 4;
    }
    .grid .has-row-md-5 {
      grid-row: span 5;
    }
    .grid .has-row-md-6 {
      grid-row: span 6;
    }
    .grid .has-row-md-7 {
      grid-row: span 7;
    }
    .grid .has-row-md-8 {
      grid-row: span 8;
    }
    .grid .has-row-md-9 {
      grid-row: span 9;
    }
    .grid .has-row-md-10 {
      grid-row: span 10;
    }
    .grid .has-row-md-11 {
      grid-row: span 11;
    }
    .grid .has-row-md-12 {
      grid-row: span 12;
    }
    .grid.has-grid-gap-md-0 {
      grid-gap: var(--spacer-0);
    }
    .grid.has-grid-gap-md-1 {
      grid-gap: var(--spacer-1);
    }
    .grid.has-grid-gap-md-2 {
      grid-gap: var(--spacer-2);
    }
    .grid.has-grid-gap-md-3 {
      grid-gap: var(--spacer-3);
    }
    .grid.has-grid-gap-md-4 {
      grid-gap: var(--spacer-4);
    }
    .grid.has-grid-gap-md-5 {
      grid-gap: var(--spacer-5);
    }
    .grid.has-grid-gap-md-6 {
      grid-gap: var(--spacer-6);
    }
    .grid.has-grid-gap-md-7 {
      grid-gap: var(--spacer-7);
    }
    .grid.has-grid-gap-md-8 {
      grid-gap: var(--spacer-8);
    }
    .grid.has-grid-gap-md-9 {
      grid-gap: var(--spacer-9);
    }
  }
  @media only screen and (min-width: 75em) {
    .grid .has-col-lg-all {
      grid-column: 1 / -1;
    }
    .grid .has-row-lg-all {
      grid-row: 1 / -1;
    }
    .grid .has-col-lg-1 {
      grid-column: span 1;
    }
    .grid.has-cols-lg-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .grid .has-col-lg-2 {
      grid-column: span 2;
    }
    .grid.has-cols-lg-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .grid .has-col-lg-3 {
      grid-column: span 3;
    }
    .grid.has-cols-lg-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .grid .has-col-lg-4 {
      grid-column: span 4;
    }
    .grid.has-cols-lg-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .grid .has-col-lg-5 {
      grid-column: span 5;
    }
    .grid.has-cols-lg-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .grid .has-col-lg-6 {
      grid-column: span 6;
    }
    .grid.has-cols-lg-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .grid .has-col-lg-7 {
      grid-column: span 7;
    }
    .grid.has-cols-lg-7 {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .grid .has-col-lg-8 {
      grid-column: span 8;
    }
    .grid.has-cols-lg-8 {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .grid .has-col-lg-9 {
      grid-column: span 9;
    }
    .grid.has-cols-lg-9 {
      grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .grid .has-col-lg-10 {
      grid-column: span 10;
    }
    .grid.has-cols-lg-10 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .grid .has-col-lg-11 {
      grid-column: span 11;
    }
    .grid.has-cols-lg-11 {
      grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .grid .has-col-lg-12 {
      grid-column: span 12;
    }
    .grid.has-cols-lg-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .grid .has-row-lg-1 {
      grid-row: span 1;
    }
    .grid .has-row-lg-2 {
      grid-row: span 2;
    }
    .grid .has-row-lg-3 {
      grid-row: span 3;
    }
    .grid .has-row-lg-4 {
      grid-row: span 4;
    }
    .grid .has-row-lg-5 {
      grid-row: span 5;
    }
    .grid .has-row-lg-6 {
      grid-row: span 6;
    }
    .grid .has-row-lg-7 {
      grid-row: span 7;
    }
    .grid .has-row-lg-8 {
      grid-row: span 8;
    }
    .grid .has-row-lg-9 {
      grid-row: span 9;
    }
    .grid .has-row-lg-10 {
      grid-row: span 10;
    }
    .grid .has-row-lg-11 {
      grid-row: span 11;
    }
    .grid .has-row-lg-12 {
      grid-row: span 12;
    }
    .grid.has-grid-gap-lg-0 {
      grid-gap: var(--spacer-0);
    }
    .grid.has-grid-gap-lg-1 {
      grid-gap: var(--spacer-1);
    }
    .grid.has-grid-gap-lg-2 {
      grid-gap: var(--spacer-2);
    }
    .grid.has-grid-gap-lg-3 {
      grid-gap: var(--spacer-3);
    }
    .grid.has-grid-gap-lg-4 {
      grid-gap: var(--spacer-4);
    }
    .grid.has-grid-gap-lg-5 {
      grid-gap: var(--spacer-5);
    }
    .grid.has-grid-gap-lg-6 {
      grid-gap: var(--spacer-6);
    }
    .grid.has-grid-gap-lg-7 {
      grid-gap: var(--spacer-7);
    }
    .grid.has-grid-gap-lg-8 {
      grid-gap: var(--spacer-8);
    }
    .grid.has-grid-gap-lg-9 {
      grid-gap: var(--spacer-9);
    }
  }
  @media only screen and (min-width: 112.5em) {
    .grid .has-col-xl-all {
      grid-column: 1 / -1;
    }
    .grid .has-row-xl-all {
      grid-row: 1 / -1;
    }
    .grid .has-col-xl-1 {
      grid-column: span 1;
    }
    .grid.has-cols-xl-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .grid .has-col-xl-2 {
      grid-column: span 2;
    }
    .grid.has-cols-xl-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .grid .has-col-xl-3 {
      grid-column: span 3;
    }
    .grid.has-cols-xl-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .grid .has-col-xl-4 {
      grid-column: span 4;
    }
    .grid.has-cols-xl-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .grid .has-col-xl-5 {
      grid-column: span 5;
    }
    .grid.has-cols-xl-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .grid .has-col-xl-6 {
      grid-column: span 6;
    }
    .grid.has-cols-xl-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .grid .has-col-xl-7 {
      grid-column: span 7;
    }
    .grid.has-cols-xl-7 {
      grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .grid .has-col-xl-8 {
      grid-column: span 8;
    }
    .grid.has-cols-xl-8 {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .grid .has-col-xl-9 {
      grid-column: span 9;
    }
    .grid.has-cols-xl-9 {
      grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .grid .has-col-xl-10 {
      grid-column: span 10;
    }
    .grid.has-cols-xl-10 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .grid .has-col-xl-11 {
      grid-column: span 11;
    }
    .grid.has-cols-xl-11 {
      grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .grid .has-col-xl-12 {
      grid-column: span 12;
    }
    .grid.has-cols-xl-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .grid .has-row-xl-1 {
      grid-row: span 1;
    }
    .grid .has-row-xl-2 {
      grid-row: span 2;
    }
    .grid .has-row-xl-3 {
      grid-row: span 3;
    }
    .grid .has-row-xl-4 {
      grid-row: span 4;
    }
    .grid .has-row-xl-5 {
      grid-row: span 5;
    }
    .grid .has-row-xl-6 {
      grid-row: span 6;
    }
    .grid .has-row-xl-7 {
      grid-row: span 7;
    }
    .grid .has-row-xl-8 {
      grid-row: span 8;
    }
    .grid .has-row-xl-9 {
      grid-row: span 9;
    }
    .grid .has-row-xl-10 {
      grid-row: span 10;
    }
    .grid .has-row-xl-11 {
      grid-row: span 11;
    }
    .grid .has-row-xl-12 {
      grid-row: span 12;
    }
    .grid.has-grid-gap-xl-0 {
      grid-gap: var(--spacer-0);
    }
    .grid.has-grid-gap-xl-1 {
      grid-gap: var(--spacer-1);
    }
    .grid.has-grid-gap-xl-2 {
      grid-gap: var(--spacer-2);
    }
    .grid.has-grid-gap-xl-3 {
      grid-gap: var(--spacer-3);
    }
    .grid.has-grid-gap-xl-4 {
      grid-gap: var(--spacer-4);
    }
    .grid.has-grid-gap-xl-5 {
      grid-gap: var(--spacer-5);
    }
    .grid.has-grid-gap-xl-6 {
      grid-gap: var(--spacer-6);
    }
    .grid.has-grid-gap-xl-7 {
      grid-gap: var(--spacer-7);
    }
    .grid.has-grid-gap-xl-8 {
      grid-gap: var(--spacer-8);
    }
    .grid.has-grid-gap-xl-9 {
      grid-gap: var(--spacer-9);
    }
  }
}
